function localCode(code: string) {
  switch (code) {
    case 'KEYCLOAK_USER_ALREADY_EXIST':
      return 'Пользователь с таким email уже зарегистрирован'
    case 'UnknownException':
      return 'Непредвиденная ошибка системы'
    case 'Invalid user credentials':
      return 'Не верный логин или пароль'
    case 'FILE_NOT_FOUND_ON_SERVER':
      return 'Файл не найден на сервере'
    case 'Internal_error_title':
      return 'Внутренняя ошибка'
    default:
      return code
  }
}

function ResponseWithMessage(value: any) {
  return {
    response: value.response,
    message: value.message,
  }
}

/**
 * Обрабатывает ошибки, полученные от сервера, и выбрасывает их с соответствующим сообщением
 * @param {object} error - объект ошибки, возвращённый сервером
 * @throws {Error} Если ошибка найдена, выбрасывается новое исключение с текстом ошибки
 */
export function errorCatcher(error: any) {
  const details = error?.response?.data?.details

  if (Array.isArray(details)) {
    details.forEach((el: any) => {
      if (el?.response) {
        throw new Error(JSON.stringify(ResponseWithMessage(el)))
      }
      else {
        throw new Error(el.message || localCode(el.code))
      }
    })
  }
  else if (details) {
    throw new Error(details ? localCode(details) : 'Непредвиденная ошибка системы')
  }
  else {
    const message = error?.response?.data?.message
    throw new Error(message ? localCode(message) : 'Непредвиденная ошибка системы')
  }
}
