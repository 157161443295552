import { markRaw } from 'vue'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { BankOutlined, DeleteOutlined, SafetyCertificateOutlined, StarOutlined } from '@ant-design/icons-vue'
import store from '@/store'
import { useShowByServer } from '@/composables/useShowByServer'

// Иконки
import QIconHome from '@/icons/QIconHome.vue'
import QIconMessage from '@/icons/QIconMessage.vue'
import QIconObjects from '@/icons/QIconObjects.vue'
import QIconProfile from '@/icons/QIconProfile.vue'
import QIconRuble from '@/icons/QIconRuble.vue'
import QIconVacancy from '@/icons/QIconVacancy.vue'
import useServiceWorker from '@/composables/useServiceWorker'

// страницы
const QAppeals = () => import('@/pages/residential/QAppeals.vue')
const QChatHelp = () => import('@/pages/residential/QChatHelp.vue')
const QChatRecalculation = () => import('@/pages/residential/QChatRecalculation.vue')
const QDashboard = () => import('@/pages/residential/QDashboard.vue')
const QObjects = () => import('@/pages/residential/QObjects.vue')
const QPayments = () => import('@/views/residential/payments/QPayments.vue')
const QProfile = () => import('@/pages/residential/QProfile.vue')
const QUnverified = () => import('@/pages/residential/QUnverified.vue')
const QVacancyVue = () => import('@/pages/residential/QVacancy.vue')
const QServicePay = () => import('@/pages/residential/QServicePay.vue')
const QInsurance = () => import('@/views/residential/insurance/QInsurance.vue')
const QInsuranceObject = () => import('@/views/residential/insurance/object/QInsuranceObject.vue')
const QLitigation = () => import('@/views/residential/litigation/QLitigation.vue')
const QWasteDisposal = () => import('@/views/residential/waste-disposal/QWasteDisposal.vue')
const QWasteDisposalRequestCreate = () => import('@/views/residential/waste-disposal/request/create/QWasteDisposalRequestCreate.vue')
const QWasteDisposalRequestEdit = () => import('@/views/residential/waste-disposal/request/edit/QWasteDisposalRequestEdit.vue')
const QAppealsRecalculation = () => import('@/views/residential/appeals/recalculation/QAppealsRecalculation.vue')
const QAppealsHelp = () => import('@/views/residential/appeals/help/QAppealsHelp.vue')

const { show } = useShowByServer()

function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (store.getters?.isAuthenticated) {
    if (store.getters.getUser?.verified === false) {
      const userId = store.getters.getUser?.id
      const query = { ...from.query, ...to.query }
      next({
        path: `/resident/unverified/${userId}`,
        query,
      })
      return
    }
    next()
  }
  else { next({ name: 'Login' }) }
}

function authUnverified(_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (store.getters?.isAuthenticated && store.getters?.getUser?.verified === false) {
    next()
  }
  else { next({ name: 'Login' }) }
}

function logicalRedirect() {
  const userId = store.getters?.getUser?.id
  if (userId) {
    return { path: `/resident/${userId}/dashboard`, params: { userId } }
  }

  else {
    useServiceWorker().disconnect()
    store.dispatch('onLogout')
  }

  return { path: '/login' }
}

function insuranceRedirect(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (show('insurance')) {
    authGuard(to, from, next)
  }
  else {
    logicalRedirect()
  }
}

export default [
  {
    path: '/resident/:userId/dashboard',
    name: 'Главный экран',
    component: QDashboard,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(QIconHome),
      abbrName: 'главный',
      tabs: false,
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/profile',
    fullPath: '/profile',
    name: 'Профиль',
    component: QProfile,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(QIconProfile),
      abbrName: 'профиль',
      tabs: true,
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/billing',
    name: 'Счета и расчёты',
    component: QPayments,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(QIconRuble),
      abbrName: 'счета',
      tabs: true,
      subHeader: {
        left: true,
      },
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/objects',
    name: 'Объекты',
    component: QObjects,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(QIconObjects),
      abbrName: 'объекты',
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/litigation',
    name: 'Судебно-претензионная работа',
    component: QLitigation,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(BankOutlined),
      abbrName: 'суд. работа',
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/insurance',
    name: 'Страхование',
    component: QInsurance,
    beforeEnter: insuranceRedirect,
    meta: {
      menu: 'main',
      icon: markRaw(SafetyCertificateOutlined),
      abbrName: 'страхование',
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/insurance/object',
    name: 'Страхование объекта',
    component: QInsuranceObject,
    beforeEnter: insuranceRedirect,
    meta: {
      layout: 'layout-main',
      backBtn: true,
      headerTitle: 'Страхование',
      steps: true,
    },
  },
  {
    path: '/resident/:userId/appeals/',
    name: 'Обращения',
    component: QAppeals,
    beforeEnter: authGuard,
    redirect: { name: 'Помощь' },
    meta: {
      menu: 'main',
      icon: markRaw(QIconMessage),
      abbrName: 'обращения',
      tabs: true,
      subHeader: {
        left: false,
      },
      layout: 'layout-main',
      headerTitle: 'Обращения',
    },
    children: [
      {
        path: 'recalculation',
        name: 'Перерасчеты',
        component: QAppealsRecalculation,
      },
      {
        path: 'help',
        name: 'Помощь',
        component: QAppealsHelp,
      },
    ],
  },
  {
    path: '/resident/:userId/chats/:id',
    name: 'Чат',
    component: QChatHelp,
    beforeEnter: authGuard,
    meta: {
      menu: null,
      layout: 'layout-main',
      subHeader: {
        left: true,
      },
      backBtn: true,
    },
  },
  {
    path: '/resident/:userId/chats-recalculation/:changeObjectId',
    name: 'Чат - перерасчет',
    component: QChatRecalculation,
    beforeEnter: authGuard,
    meta: {
      menu: null,
      layout: 'layout-main',
      subHeader: {
        left: true,
      },
      backBtn: true,
    },
  },
  {
    path: '/resident/unverified/:userId',
    name: 'Вы не верифицированы',
    component: QUnverified,
    beforeEnter: authUnverified,
    meta: {
      menu: null,
      layout: 'layout-unverified',
    },
  },
  {
    path: '/resident/:userId/vacancy',
    fullPath: '/vacancy',
    name: 'Вакансии',
    component: QVacancyVue,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(QIconVacancy),
      abbrName: 'вакансии',
      layout: 'layout-main',
    },
  },
  {
    path: '/resident',
    redirect: logicalRedirect,
    meta: {
      menu: null,
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/service-pay',
    name: 'Оплата услуг',
    component: QServicePay,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(StarOutlined),
      abbrName: 'оплата услуг',
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/waste-disposal',
    name: 'Индивидуальный вывоз',
    component: QWasteDisposal,
    beforeEnter: authGuard,
    meta: {
      menu: 'main',
      icon: markRaw(DeleteOutlined),
      abbrName: 'инд. вывоз',
      tabs: true,
      layout: 'layout-main',
    },
  },
  {
    path: '/resident/:userId/waste-disposal/request/create',
    name: 'Создание заявки на индивидуальный вывоз',
    component: QWasteDisposalRequestCreate,
    beforeEnter: authGuard,
    meta: {
      menu: null,
      layout: 'layout-main',
      subHeader: {
        left: true,
      },
      backBtn: true,
    },
  },
  {
    path: '/resident/:userId/waste-disposal/request/:requestId/edit',
    name: 'Редактирование заявки на индивидуальный вывоз',
    component: QWasteDisposalRequestEdit,
    beforeEnter: authGuard,
    meta: {
      menu: null,
      layout: 'layout-main',
      subHeader: {
        left: true,
      },
      backBtn: true,
    },
  },
]
