import { Empty } from 'ant-design-vue'

export const SIMPLE_IMAGE = Empty.PRESENTED_IMAGE_SIMPLE

export const MESSAGE_NESS_FIELD = 'Обязательное поле'
export const MESSAGE_CHECK_DATA = 'Проверьте правильность введенных данных'
export const MESSAGE_DATA_SUCCESSFUL = 'Данные успешно сохранены'
export const MESSAGE_REAL_ESTATE_EMPTY_GAR = 'Не определен ГАР объекта недвижимости'
export const MESSAGE_FORM_ERROR = 'Ошибка заполнения формы'
export const MESSAGE_FILES_REQUIRED = 'Прикрепите документы'

export const VALID_MESSAGE_ERROR_NUMBER = 'Только цифры'
export const VALID_MESSAGE_WARNING_PHONE_CHECK = 'Проверьте номер телефона, возможно он введен неверно'

export const MESSAGE_INPUT_PHONE = 'Введите номер телефона'
export const MESSAGE_INPUT_EMAIL = 'Введите электронную почту'
export const MESSAGE_INPUT_CORRECT_EMAIL = 'Введите правильный email'
export const MESSAGE_INN_ERROR = 'Ошибка при заполнении ИНН'
export const MESSAGE_SNILS_ERROR = 'Ошибка при заполнении СНИЛС'
export const MESSAGE_NEED_DOCUMENTS_EVERY_TYPE = 'Загрузите документы каждого типа'
export const MESSAGE_CURRENCY_SUM_ERROR = 'Неверный формат данных. Должно быть число c разделителем ‘.’ (одним либо два знака после) или без него'
export const MESSAGE_AUTOPAY_DELETED = 'Автоплатёж удален'
export const MESSAGE_MAX_LENGTH_VALID = 'Разрешено максимум 10 000 символов'
export const MESSAGE_MAX_LENGTH_TOPIC = 'Тема обращения не должна превышать 250 символов'
export const MESSAGE_FIO_RU_VALID = 'Разрешена только кириллица'
export const MESSAGE_SUCCESS_DOWNLOAD_FILE = 'Файл успешно скачан'
export const MESSAGE_HIDE_INFO_MODAL = 'Данное уведомление будет скрыто'

export const UNFILLED = 'не заполнено'
export const EMPTY_DATA = 'Нет данных'

export const MODAL_CLOSING_TIME = 2500
export const DEBOUNCE_INPUT_TIME = 800

export const DEFAULT_TEXT_LENGTH = 10000
export const COLOR_PRIMARY = '#1890ff'

export const SELECT_FIELD_NAMES_ID_NAME = { label: 'name', value: 'id' }
export const RULE_REQUIRED = { required: true, message: MESSAGE_NESS_FIELD }

export const MILLISECONDS_LONG_POOLING = 15000
export const MILLISECONDS = 700

// FIXME: костыль - тип документа, который бекэндеры должны были завести на у себя и отдавать на фронт. Временно выдали такой id
export const DOCUMENT_TYPE_ID = '72d835e7-3e87-dddf-b73f-3fde0c7e2249'
export const DOCUMENT_TYPE_ID_WASTE_DISPOSAL = 'ed027c02-fa50-d9d0-9990-c0295d004cbb'

export const FORM_DATEPICKER_PLACEHOLDER = 'Выберите дату'

export const IMAGE_EXTENSIONS = ['JPG', 'JPEG', 'PNG']
