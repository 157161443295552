import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-userbar" }
const _hoisted_2 = { class: "q-userbar__info" }
const _hoisted_3 = { class: "q-userbar__info-number" }
const _hoisted_4 = { class: "q-userbar__info-name" }
const _hoisted_5 = { class: "q-userbar__icon" }

import { computed } from 'vue'
import { BellOutlined } from '@ant-design/icons-vue'
import { Badge as ABadge } from 'ant-design-vue'
import type { ITask } from '@/interfaces'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'QUserbar',
  setup(__props) {

const user = computed(() => store?.getters?.getUser)

const countNotifications = computed<number>(() => {
  const task: ITask | null = store.getters.getTask
  const recalculations = task?.changeObjects?.length || 0
  const chats = task?.chats?.length || 0
  return recalculations + chats
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, " Л/С " + _toDisplayString(user.value?.numberPersonalAccount || 'Не определен'), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(user.value?.fullName || 'Пользователь'), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        class: "q-userbar__link",
        to: `/resident/${user.value?.id}/appeals`
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(ABadge), {
            disabled: true,
            count: countNotifications.value,
            "number-style": { backgroundColor: '#1890FF' }
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BellOutlined))
            ]),
            _: 1
          }, 8, ["count"])
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}
}

})