import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-navbar" }
const _hoisted_2 = { class: "q-navbar__logo" }
const _hoisted_3 = { class: "q-navbar__menu" }
const _hoisted_4 = { class: "q-navbar__exit" }

import { onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import type { INavbarItem } from './interfaces'
import QIconLogo from '@/icons/QIconLogo.vue'
import QIconLogoSmall from '@/icons/QIconLogoSmall.vue'
import QNavbarItem from '@/components/navbar/QNavbarItem.vue'
import QNavbarExit from '@/components/navbar/QNavbarExit.vue'
import QNavbarMore from '@/components/navbar/QNavbarMore.vue'
import { useShowByServer } from '@/composables/useShowByServer'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'QNavbar',
  setup(__props) {

const { show } = useShowByServer()

const router = useRouter()

const items = ref<INavbarItem[]>([])
const moreItems = ref<INavbarItem[]>([])
const screenWidth = ref(window.innerWidth)

function findSeparateIndex(arrayLength: number) {
  let index = arrayLength
  // ширина одной ячейки меню
  const step = 60
  const maxWidth = arrayLength * step

  if (screenWidth.value < maxWidth) {
    for (let i = 1; i < arrayLength; i++) {
      if (screenWidth.value >= i * step) {
        index = i - 1
      }
    }
  }

  return index
}

function initData() {
  const itemList: INavbarItem[] = []
  router.options?.routes?.forEach((route) => {
    if (route.meta?.menu === 'main') {
      if (
        (route.name === 'Оплата услуг' && !show('autopay'))
        || (route.name === 'Страхование' && !show('insurance'))
        || (route.name === 'Индивидуальный вывоз' && !store.getters.getUser?.wasteDisposal)
      ) {
        return
      }
      itemList.push({
        ...route,
        name: route.name,
        path: route.path,
        icon: route.meta?.icon,
      })
    }
  })

  const endIndex = findSeparateIndex(itemList.length)

  items.value = itemList.slice(0, endIndex)
  moreItems.value = itemList.slice(endIndex, itemList.length)
}

function updateScreenWidth() {
  screenWidth.value = window.innerWidth
  initData()
}

// Следить за размером экрана
function onScreenResize() {
  window.addEventListener('resize', () => updateScreenWidth())
}

onMounted(() => {
  initData()
  onScreenResize()
})

onUnmounted(() => window.removeEventListener('resize', () => updateScreenWidth()))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(QIconLogo, { class: "q-navbar__logo_large" }),
      _createVNode(QIconLogoSmall, { class: "q-navbar__logo_small" })
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
        return (_openBlock(), _createBlock(QNavbarItem, {
          item: item,
          key: item.name
        }, null, 8, ["item"]))
      }), 128)),
      (moreItems.value.length)
        ? (_openBlock(), _createBlock(QNavbarMore, {
            key: 0,
            items: moreItems.value
          }, null, 8, ["items"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      _createVNode(QNavbarExit)
    ])
  ]))
}
}

})