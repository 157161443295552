import { notification } from 'ant-design-vue'
import type { ITerminalNotification } from './interfaces'
import store from '@/store'

function openNotification(message: ITerminalNotification) {
  notification[message.status]({
    message: message.type,
    description: message.messageText,
    placement: 'topRight',
  })
}

function useServiceWorker() {
  function sendMessage(message: any) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          const worker = registration.installing || registration.waiting || registration.active
          worker?.postMessage(message)
        })
    }
  }

  const parsingMessage = (message: any) => {
    if (message.type === 'NOTIFICATION') {
      store?.dispatch('getTasks')
    }

    if (message.type === 'STOP') {
      store.dispatch('disconnect')
    }

    // SUBSCRIBE_DATA
    if (message.type === 'task') {
      store.commit('setTask', message.value)
    }
    if (message.type === 'terminal' && message.value) {
      openNotification(message.value)
    }
  }

  const disconnect = () => sendMessage({ type: 'STOP' })
  const start = () => sendMessage({ type: 'START' })

  const getNotification = () => sendMessage({ type: 'NOTIFICATION' })

  return {
    start,
    disconnect,
    parsingMessage,
    sendMessage,
    getNotification,
  }
}

export default useServiceWorker
