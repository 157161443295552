<template>
  <div class="q-userbar">
    <div class="q-userbar__info">
      <div class="q-userbar__info-number">
        Л/С {{ user?.numberPersonalAccount || 'Не определен' }}
      </div>
      <div class="q-userbar__info-name">
        {{ user?.fullName || 'Пользователь' }}
      </div>
    </div>
    <div class="q-userbar__icon">
      <router-link class="q-userbar__link" :to="`/resident/${user?.id}/appeals`">
        <ABadge
          :disabled="true"
          :count="countNotifications"
          :number-style="{ backgroundColor: '#1890FF' }"
        >
          <BellOutlined />
        </ABadge>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { BellOutlined } from '@ant-design/icons-vue'
import { Badge as ABadge } from 'ant-design-vue'
import type { ITask } from '@/interfaces'
import store from '@/store'

const user = computed(() => store?.getters?.getUser)

const countNotifications = computed<number>(() => {
  const task: ITask | null = store.getters.getTask
  const recalculations = task?.changeObjects?.length || 0
  const chats = task?.chats?.length || 0
  return recalculations + chats
})
</script>

<style lang="sass" scoped>
@import './QUserbar'
</style>
