<template>
  <div class="q-header-top">
    <span class="q-header-top__left">
      <QHeaderBack v-if="route?.meta?.backBtn" />
      <div v-else class="q-header-top__left-logo">
        <QIconLogoSmall />
      </div>
      <h2 class="q-header-top__left-title">
        {{ route?.meta?.headerTitle ?? route?.query?.name ?? route?.name }}
      </h2>
    </span>
    <div class="q-header-top__steps" id="steps" />
    <div class="q-header-top__right">
      <div class="q-header-top__buttons" id="headerButtons" />
      <span class="q-header-top__user">
        <ADropdown>
          <QUserbar />
          <template #overlay>
            <QHeaderNotifications />
          </template>
        </ADropdown>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { Dropdown as ADropdown } from 'ant-design-vue'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import QHeaderNotifications from './QHeaderNotifications.vue'
import QIconLogoSmall from '@/icons/QIconLogoSmall.vue'
import QUserbar from '@/components/ui/userbar/QUserbar.vue'
import QHeaderBack from '@/components/header/QHeaderBack.vue'
import type { IStore } from '@/store/interfaces'

const route = useRoute()
const store: IStore = useStore()

onMounted(() => store?.dispatch('getTasks'))
</script>

<style lang="sass" scoped>
@import './QHeaderTop'
</style>
