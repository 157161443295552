import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ruRU from 'ant-design-vue/es/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  ConfigProvider as AConfigProvider,
  StyleProvider as AStyleProvider,
} from 'ant-design-vue'
import type { ThemeConfig } from 'ant-design-vue/lib/config-provider/context'
import { useCookies } from './composables/useCookies'
import useServiceWorker from './composables/useServiceWorker'
import { MILLISECONDS_LONG_POOLING } from './constants'
import QCookieAgreement from '@/components/cookie-agreement/QCookieAgreement.vue'
import { useTheme } from '@/composables/useTheme'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

dayjs.locale('ru')
const route = useRoute()
const locale = ref(ruRU)
const interval = ref<number>()
const layout = computed(() => route.meta.layout)
const wasAgreement = ref(true)

const theme: ThemeConfig = {
  components: {
    Modal: {
      padding: 0,
      paddingContentHorizontalLG: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
    },
    Tag: {
      colorTextLightSolid: 'currentColor',
    },
  },
}

onMounted(() => {
  // Отключить новогоднюю тему 20.02.2025
  useTheme().setTheme('default')

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', event => useServiceWorker().parsingMessage(event.data))
  }

  interval.value = setInterval(() => {
    if (route.name !== 'Login' && store.getters.isAuthenticated) {
      useServiceWorker().getNotification()
    }
  }, MILLISECONDS_LONG_POOLING)

  setTimeout(() => {
    wasAgreement.value = useCookies().checkAgreement()
  }, 3000)
})

onUnmounted(() => clearInterval(interval.value))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AConfigProvider), {
    locale: locale.value,
    theme: theme
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(AStyleProvider), { "hash-priority": "high" }, {
        default: _withCtx(() => [
          (layout.value)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(layout.value), { key: 0 }))
            : _createCommentVNode("", true),
          (!wasAgreement.value)
            ? (_openBlock(), _createBlock(QCookieAgreement, {
                key: 1,
                onClose: _cache[0] || (_cache[0] = ($event: any) => (wasAgreement.value = true))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})