import type { Module } from 'vuex'
import { message } from 'ant-design-vue'
import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import type { IRootStore } from '../interfaces'
import type { INotificationState } from './interfaces'
import CommunicationService from '@/services/main/CommunicationService'
import useServiceWorker from '@/composables/useServiceWorker'

const webSocketErrorList = [
  'Cannot connect to server',
  'Недействительный токен',
  'Отсутствует токен',
  'Не валидный токен',
  'Нет прав',
  'Не удалось определить идентификатор пользовательской сессии',
]

function createWebSocket() {
  try {
    return new SockJS(process.env.VUE_APP_API_WS)
  }
  catch (error) {
    console.error('Ошибка при создании WebSocket:', error)
  }
}

const webSocket: Module<INotificationState, IRootStore> = {
  state: {
    client: undefined,
    stop: false,
    countNotifications: 0,
    task: null,
  },

  getters: {
    countNotifications: state => state.countNotifications,
    getTask: state => state.task,
  },

  mutations: {
    setClient: (state, data) => {
      state.client = data
    },
    setCount: (state, data) => {
      state.countNotifications = data
    },
    setTask: (state, data) => {
      state.task = data
    },
  },

  actions: {
    async getNotifications({ commit }) {
      try {
        const { data } = await CommunicationService.getUnreadMessages()
        commit('setCount', data)
      }
      catch (err: any) {
        message.error(err.response.data?.details)
      }
    },

    async getTasks({ commit }) {
      try {
        const { data } = await CommunicationService.getTasks()
        commit('setTask', data)
        useServiceWorker().sendMessage({ type: 'task', value: data })
      }
      catch (err: any) {
        console.error(err.response.data?.details)
      }
    },

    updateTask({ commit }, data) {
      commit('setTask', data)
      const tasks = Number(data?.changeObjects?.length) || 0
      const chats = Number(data?.chats?.length) || 0
      const notificationCount = tasks + chats
      commit('setCount', notificationCount)
    },

    async subscribeToRoom({ state }, { userId, authorization }) {
      let subscription
      try {
        subscription = await state.client?.subscribe(`/user/${userId}/task`, (message: any) => {
          useServiceWorker().sendMessage({ type: 'task', value: JSON.parse(message.body) })
        }, { authorization })
      }
      catch (error) {
        console.error('[WebSocket] Не удалось подписаться на комнату', error)
        subscription?.unsubscribe()
      }
    },

    async subscribeToTerminal({ state }, { userId, authorization }) {
      let subscription
      try {
        subscription = await state.client?.subscribe(`/user/${userId}/terminalmessage`, (message) => {
          useServiceWorker().sendMessage({ type: 'terminal', value: JSON.parse(message.body) })
        }, { authorization })
      }
      catch (error) {
        console.error('[WebSocket] Не удалось подписаться на комнату', error)
        subscription?.unsubscribe()
      }
    },

    disconnect({ state }) {
      localStorage.removeItem('task')
      state.task = null
      state.client?.deactivate()
      // Отменяем запрет на подключения
      state.stop = false
    },

    connect({ dispatch, state }, { token, userId }) {
      if (!state.client?.connected) {
        state.client = new Client({
          webSocketFactory: createWebSocket,
          connectHeaders: {
            authorization: `${token}`,
          },
          reconnectDelay: 5000,
          beforeConnect: () => {
            return new Promise((resolve) => {
              resolve()
            })
          },
          onConnect: () => {
            dispatch('subscribeToRoom', { userId, token })
            dispatch('subscribeToTerminal', { userId, token })
          },
          onDisconnect: (frame) => {
            console.log('[WebSocket] onDisconnect', frame)
          },
          onStompError: (error) => {
            console.error('[WebSocket] onStompError', error)
          },
          onWebSocketClose: (error) => {
            console.log('[WebSocket] onWebSocketClose', error)
            if (webSocketErrorList.includes(error?.reason)) {
              // Запрет на подключения
              state.stop = true
              state.client?.deactivate()
            }
          },
          onWebSocketError: (error) => {
            console.error('[WebSocket] onWebSocketError:', error)
          },
        })
        state.client?.activate()
      }
    },
  },
}

export default webSocket
