import { DefaultAPIInstance } from '@/api'
import { errorCatcher } from '@/utils/showErrors'

const ContragentService = {
  getUser() {
    return DefaultAPIInstance.get('main/user/')
  },
  getContragent(userId) {
    return DefaultAPIInstance.get(`client/contragent/${userId}`)
  },
  changeContragent(id) {
    return DefaultAPIInstance.put(`client/user/select_contragent/${id}`)
  },
  async dontShowInfoModalAgain(userId, modalId) {
    try {
      const url = `client/${userId}/popupwindow/${modalId}`
      const { data } = await DefaultAPIInstance.post(url)
      return data
    }
    catch (error) {
      errorCatcher(error)
    }
  },
}

export default ContragentService
