<template>
  <AConfigProvider
    :locale="locale"
    :theme="theme"
  >
    <AStyleProvider hash-priority="high">
      <component :is="layout" v-if="layout" />
      <QCookieAgreement
        v-if="!wasAgreement"
        @close="wasAgreement = true"
      />
    </AStyleProvider>
  </AConfigProvider>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ruRU from 'ant-design-vue/es/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  ConfigProvider as AConfigProvider,
  StyleProvider as AStyleProvider,
} from 'ant-design-vue'
import type { ThemeConfig } from 'ant-design-vue/lib/config-provider/context'
import { useCookies } from './composables/useCookies'
import useServiceWorker from './composables/useServiceWorker'
import { MILLISECONDS_LONG_POOLING } from './constants'
import QCookieAgreement from '@/components/cookie-agreement/QCookieAgreement.vue'
import { useTheme } from '@/composables/useTheme'
import store from '@/store'

dayjs.locale('ru')
const route = useRoute()
const locale = ref(ruRU)
const interval = ref<number>()
const layout = computed(() => route.meta.layout)
const wasAgreement = ref(true)

const theme: ThemeConfig = {
  components: {
    Modal: {
      padding: 0,
      paddingContentHorizontalLG: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
    },
    Tag: {
      colorTextLightSolid: 'currentColor',
    },
  },
}

onMounted(() => {
  // Отключить новогоднюю тему 20.02.2025
  useTheme().setTheme('default')

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', event => useServiceWorker().parsingMessage(event.data))
  }

  interval.value = setInterval(() => {
    if (route.name !== 'Login' && store.getters.isAuthenticated) {
      useServiceWorker().getNotification()
    }
  }, MILLISECONDS_LONG_POOLING)

  setTimeout(() => {
    wasAgreement.value = useCookies().checkAgreement()
  }, 3000)
})

onUnmounted(() => clearInterval(interval.value))
</script>
